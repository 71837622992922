import { type HeadersFunction } from '@remix-run/node';
import { Link, type LinkProps } from '@remix-run/react';
import { add, format, parseISO } from 'date-fns';
import es from 'date-fns/locale/es/index.js';
import md5 from 'md5-hash';
import * as React from 'react';
import { images } from '~/images.tsx';
import { type NonNullProperties } from '~/types.tsx';
import { type getEnv } from '~/utils/env.server.ts';
// eslint-disable-next-line import/no-extraneous-dependencies
import { utcToZonedTime } from 'date-fns-tz';

export function getStartTimeWithTZ(startTime: string | null, timezone: string | null): string {
  const appointmentTime = utcToZonedTime(startTime ?? '', timezone ?? 'America/Lima');
  return format(appointmentTime, 'h:mmaaa  PPPP', { locale: es });
}

export function getOrigin(requestInfo?: { origin?: string; path: string }) {
  return requestInfo?.origin ?? 'https://ilagine.com';
}

export function isResponse(response: unknown): response is Response {
  return (
    typeof response === 'object' &&
    response !== null &&
    'status' in response &&
    'headers' in response &&
    'body' in response
  );
}

export function getRequiredEnvVarFromObj(
  obj: Record<string, string | undefined>,
  key: string,
  devValue = `${key}-dev-value`
): string {
  let value = devValue;
  const envVal = obj[key];
  if (envVal) {
    value = envVal;
  } else if (obj.NODE_ENV === 'production') {
    throw new Error(`${key} is a required env variable`);
  }
  return value;
}

export function getRequiredServerEnvVar(key: string, devValue?: string): string {
  return getRequiredEnvVarFromObj(process.env, key, devValue);
}

export function getRequiredGlobalEnvVar(key: keyof ReturnType<typeof getEnv>, devValue?: string): string {
  return getRequiredEnvVarFromObj(ENV, key, devValue);
}

export function getDomainUrl(request: Request): string {
  const host = request.headers.get('X-Forwarded-Host') ?? request.headers.get('host');
  if (!host) {
    throw new Error('Could not determine domain URL.');
  }
  const protocol = host.includes('localhost') ? 'http' : 'https';
  return `${protocol}://${host}`;
}

type AnchorProps = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

export const AnchorOrLink = React.forwardRef<
  HTMLAnchorElement,
  AnchorProps & {
    reload?: boolean;
    to?: LinkProps['to'];
    prefetch?: LinkProps['prefetch'];
  }
>(function AnchorOrLink(props, ref) {
  const { to, href, download, reload = false, prefetch, children, ...rest } = props;
  let toUrl = '';
  let shouldUserRegularAnchor = reload || download;

  if (!shouldUserRegularAnchor && typeof href === 'string') {
    shouldUserRegularAnchor = href.includes(':') || href.startsWith('#');
  }

  if (!shouldUserRegularAnchor && typeof to === 'string') {
    toUrl = to;
    shouldUserRegularAnchor = to.includes(':');
  }

  if (!shouldUserRegularAnchor && typeof to === 'object') {
    toUrl = `${to.pathname ?? ''}${to.hash ? `#${to.hash}` : ''}${to.search ? `?${to.search}` : ''}`;
    shouldUserRegularAnchor = to.pathname?.includes(':');
  }

  if (shouldUserRegularAnchor) {
    return (
      <a {...rest} download={download} href={href ?? toUrl} ref={ref}>
        {children}
      </a>
    );
  } else {
    return (
      <Link prefetch={prefetch} to={to ?? href ?? ''} {...rest} ref={ref}>
        {children}
      </Link>
    );
  }
});

export const useSSRLayoutEffect = typeof window === 'undefined' ? () => {} : React.useLayoutEffect;

export function typedBoolean<T>(value: T): value is Exclude<T, '' | 0 | false | null | undefined> {
  return Boolean(value);
}

export function removeTrailingSlash(s: string): string {
  return s.endsWith('/') ? s.slice(0, -1) : s;
}

export function getUrl(requestInfo?: { origin: string; path: string }): string {
  return removeTrailingSlash(`${requestInfo?.origin ?? 'https://ilagine.com'}${requestInfo?.path ?? ''}`);
}

export function getDisplayUrl(requestInfo?: { origin: string; path: string }): string {
  return getUrl(requestInfo).replace(/^https?:\/\//, '');
}

export const formatNumber = (num: number): string => new Intl.NumberFormat().format(num);

function parseDate(dateString: string) {
  return add(parseISO(dateString), {
    minutes: new Date().getTimezoneOffset()
  });
}

export function formatDate(dateString: string | Date, formating = 'PPP') {
  if (typeof dateString !== 'string') {
    dateString = dateString.toISOString();
  }
  return format(parseDate(dateString), formating, { locale: es });
}

export const reuseUsefulLoaderHeaders: HeadersFunction = ({ loaderHeaders }) => {
  const headers = new Headers();
  const usefulHeaders = ['Cache-Control', 'Vary', 'Server-Timing'];
  for (const headerName of usefulHeaders) {
    if (loaderHeaders.has(headerName)) {
      headers.set(headerName, loaderHeaders.get(headerName)!);
    }
  }

  return headers;
};

const defaultAvatarSize = 128;
export function getAvatar(
  email: string,
  {
    size = defaultAvatarSize,
    fallback = images.avatar({ resize: { width: size } }),
    origin
  }: { size?: number; fallback?: string | null; origin?: string } = {}
) {
  const hash = md5.default(email);
  const url = new URL(`https://www.gravatar.com/avatar/${hash}`);
  url.searchParams.set('size', String(size));
  if (fallback) {
    if (origin && fallback.startsWith('/')) {
      fallback = `${origin}${fallback}`;
    }
    url.searchParams.set('default', fallback);
  }
  return url.toString();
}

export function formatAbbreviatedNumber(num: number) {
  return num < 1_000
    ? formatNumber(num)
    : num < 1_000_000
    ? `${formatNumber(Number((num / 1_000).toFixed(2)))}k`
    : num < 1_000_000_000
    ? `${formatNumber(Number((num / 1_000_000).toFixed(2)))}m`
    : num < 1_000_000_000_000
    ? `${formatNumber(Number((num / 1_000_000_000).toFixed(2)))}b`
    : 'bastante';
}

export function useUpdateQueryStringValueWithoutNavigation(queryKey: string, queryValue: string) {
  React.useEffect(() => {
    const currentSearchParams = new URLSearchParams(window.location.search);
    const oldQuery = currentSearchParams.get(queryKey) ?? '';
    if (queryValue === oldQuery) return;

    if (queryValue) {
      currentSearchParams.set(queryKey, queryValue);
    } else {
      currentSearchParams.delete(queryKey);
    }
    const newUrl = [window.location.pathname, currentSearchParams.toString()].filter(Boolean).join('?');
    // alright, let's talk about this...
    // Normally with remix, you'd update the params via useSearchParams from react-router-dom
    // and updating the search params will trigger the search to update for you.
    // However, it also triggers a navigation to the new url, which will trigger
    // the loader to run which we do not want because all our data is already
    // on the client and we're just doing client-side filtering of data we
    // already have. So we manually call `window.history.pushState` to avoid
    // the router from triggering the loader.
    window.history.replaceState(null, '', newUrl);
  }, [queryKey, queryValue]);
}

export function getErrorMessage(error: unknown) {
  if (typeof error === 'string') return error;
  if (error instanceof Error) return error.message;
  return 'Unknown Error';
}

function assertNonNull<PossibleNullType>(
  possibleNull: PossibleNullType,
  errorMessage: string
): asserts possibleNull is Exclude<PossibleNullType, null | undefined> {
  if (possibleNull == null) throw new Error(errorMessage);
}

export function getNonNull<Type extends Record<string, null | undefined | unknown>>(
  obj: Type
): NonNullProperties<Type> {
  for (const [key, val] of Object.entries(obj)) {
    assertNonNull(val, `The value of ${key} is null but it should not be.`);
  }
  return obj as NonNullProperties<Type>;
}

export function getErrorStack(error: unknown) {
  if (typeof error === 'string') return error;
  if (error instanceof Error) return error.stack;
  return 'Unknown Error';
}

function debounce<Callback extends (...args: Array<unknown>) => void>(fn: Callback, delay: number) {
  let timer: ReturnType<typeof setTimeout> | null = null;
  return (...args: Parameters<Callback>) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

export function useDebounce<Callback extends (...args: Array<unknown>) => unknown>(callback: Callback, delay: number) {
  const callbackRef = React.useRef(callback);
  React.useEffect(() => {
    callbackRef.current = callback;
  });
  return React.useMemo(() => debounce((...args) => callbackRef.current(...args), delay), [delay]);
}

function callAll<Args extends Array<unknown>>(...fns: Array<((...args: Args) => unknown) | undefined>) {
  return (...args: Args) => fns.forEach(fn => fn?.(...args));
}

export function useDoubleCheck() {
  const [doubleCheck, setDoubleCheck] = React.useState(false);

  function getButtonProps(props?: JSX.IntrinsicElements['button']) {
    const onBlur: JSX.IntrinsicElements['button']['onBlur'] = () => setDoubleCheck(false);

    const onClick: JSX.IntrinsicElements['button']['onClick'] = doubleCheck
      ? undefined
      : e => {
          e.preventDefault();
          setDoubleCheck(true);
        };

    return {
      ...props,
      onBlur: callAll(onBlur, props?.onBlur),
      onClick: callAll(onClick, props?.onClick)
    };
  }

  return { doubleCheck, getButtonProps };
}

/**
 * Provide a condition and if that condition is falsey, this throws an error
 * with the given message.
 *
 * inspired by invariant from 'tiny-invariant' except will still include the
 * message in production.
 *
 * @example
 * invariant(typeof value === 'string', `value must be a string`)
 *
 * @param condition The condition to check
 * @param message The message to throw (or a callback to generate the message)
 * @param responseInit Additional response init options if a response is thrown
 *
 * @throws {Error} if condition is falsey
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function invariant(condition: any, message: string | (() => string)): asserts condition {
  if (!condition) {
    throw new Error(typeof message === 'function' ? message() : message);
  }
}
